<template>
  <div>
    <label>
      <span :class="$style.visuallyHidden">Фильтр по слову</span>
      <input
        v-model="filter"
        :class="$style.input"
        type="text"
        placeholder="Фильтр по слову"
      >
    </label>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: 'filter',
    },
  },
  computed: {
    filter: {
      get() {
        return this.$route.query[this.name];
      },
      set(val) {
        if (val) {
          this.$router.replace({
            query: { ...this.$route.query, [this.name]: val },
          });
        } else {
          const query = { ...this.$route.query };
          delete query[this.name];
          this.$router.replace({ query });
        }
      },
    },
  },
  created() {
    const route = `$route.query.${this.name}`;
    this.$watch(route, function (val) {
      this.filter = val;
    });
  },
};
</script>

<style lang="scss" module>
.visuallyHidden {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}

.input {
  border: 1px solid $borderColor;
  color: $black;
  font-size: 14px;
  line-height: 20px;
  padding: 6px 12px;

  &::placeholder {
    color: dakren($dark-gray, 3%);
  }
}
</style>

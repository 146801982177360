<template>
  <div :class="$style.root">
    <search />
    <div>
      <router-link
        :to="getRouterLink('table')"
        :class="[
          $style.button,
          { [$style.active]: $route.query.view === 'table' },
        ]"
        replace
      >
        Таблица
      </router-link>
      <router-link
        :to="getRouterLink('grid')"
        :class="[
          $style.button,
          { [$style.active]: $route.query.view === 'grid' },
        ]"
        replace
      >
        Эскизы
      </router-link>
    </div>
    <!-- <button
      type="button"
      :class="$style.btnExport"
      @click.prevent="printHandler"
    >
      <inline-svg
        :src="
          require('@/assets/images/icons/computer/screenshots/modal/download.svg')
        "
        alt="Загрузить"
        title="Загрузить"
        :class="$style.mr"
      />Экспорт
    </button>-->
    <div v-if="visible" ref="printContainer" :class="$style.hidden">
      <screenshots-table />
    </div>
    <export />
  </div>
</template>

<script>
// import { InlineSvgComponent } from 'vue-inline-svg';
import ScreenshotsTable from '@/components/common/export/screenshots-table.vue';
import Search from '@/components/pages/computers/log/screenshots/components-header/search.vue';
import Export from '@/components/pages/computers/log/screenshots/components-header/export.vue';

export default {
  components: {
    // inlineSvg: InlineSvgComponent,
    ScreenshotsTable,
    Search,
    Export,
  },
  props: {
    selectedComputer: {
      type: null,
      default: () => {},
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    getRouterLink(view) {
      return { query: { ...this.$route.query, view } };
    },
    printHandler() {
      this.visible = true;
      this.$nextTick(() => {
        // Get all stylesheets HTML
        let stylesHtml = '';
        [...document.querySelectorAll('link[rel="stylesheet"], style')].forEach(
          (item) => {
            stylesHtml += item.outerHTML;
          },
        );

        // Open the print window
        const WinPrint = window.open(
          '',
          '',
          'left=0,top=0,width=1200,height=900,toolbar=0,scrollbars=0,status=0',
        );

        WinPrint.document.write(
          `
          <!DOCTYPE html>
          <html>
            <head>
              ${stylesHtml}
            </head>
            <body>
              ${this.$refs.printContainer.innerHTML}
            </body>
          </html>
        `,
        );

        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.print();
        WinPrint.close();
        this.visible = false;
      });
    },
  },
};
</script>

<style lang="scss" module>
.root {
  display: flex;
  justify-content: space-between;
}

.button {
  background: $light-gray;
  border: 1px solid $borderColor;
  border-radius: 2px;
  color: $light-black;
  font-size: 14px;
  text-decoration: none;
  display: inline-block;
  padding: 10px 20px;
  transition: 0.1s ease-out;

  &:not(:last-child) {
    margin-right: 12px;
  }

  &.active {
    background: $light-blue;
    color: $blue;
  }
}

.btnExport {
  background: $light-blue;
  border: none;
  border-radius: 2px;
  color: $blue;
  font-size: 14px;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  transition: 0.1s ease-out;
}

.mr {
  margin-right: 10px;
}

.hidden {
  display: none;
}
</style>

<template>
  <section :class="$style.container">
    <h2 :class="$style.title">
      Данные по скриншотам
    </h2>
    <p :class="$style.date">
      от
      <b>{{ dateFrom | date }}</b> по
      <b>{{ dateTo | date }}</b>
    </p>
    <hr>
    <div :class="$style.info">
      <b :class="$style.infoTitle">Данные компьютера:</b>
      <div :class="$style.infoWrapper">
        <p :class="$style.infoText">
          Имя компьютера:
          <span>{{ nameComputer }}</span>
        </p>
        <p :class="$style.infoText">
          IP адрес:
          <span>{{ ipComputer }}</span>
        </p>
        <p :class="$style.infoText">
          Mac адрес:
          <span>{{ macComputer }}</span>
        </p>
      </div>
    </div>

    <div
      v-for="(item, indx) in screenshots"
      :key="indx"
      :class="$style.screenshot"
    >
      <table :class="$style.table">
        <tr>
          <th>Имя пользователя</th>
          <th>Заголовок окна</th>
          <th>Время</th>
        </tr>
        <tr>
          <td>{{ item.username }}</td>
          <td>{{ item.caption }}</td>
          <td>{{ item.date | date }}</td>
        </tr>
      </table>

      <div :class="$style.images">
        <img :src="getScreenshotUrl(item.path)" :alt="item.caption">
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { date } from '@/helpers/filters';
import { getScreenshotUrl } from '@/helpers/methods';

export default {
  filters: {
    date,
  },
  computed: {
    dateFrom() {
      return new Date(this.$route.query.dateFrom);
    },
    dateTo() {
      return new Date(this.$route.query.dateTo);
    },
    ...mapState({
      nameComputer: (state) => state.printScreenshots.nameComputer,
      ipComputer: (state) => state.printScreenshots.ipComputer,
      macComputer: (state) => state.printScreenshots.macComputer,
      screenshots: (state) => state.printScreenshots.screenshots,
    }),
  },
  methods: {
    getScreenshotUrl,
  },
};
</script>

<style lang="scss" module>
.container {
  padding-top: 15px;
  padding-bottom: 15px;
}

.title {
  font-size: 30px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 10px;
}

.date {
  font-size: 20px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 15px;
}

.info {
  padding: 15px;
  margin-bottom: 30px;
}

.infoTitle {
  font-size: 20px;
  display: block;
  margin-bottom: 15px;
}

.infoWrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.infoText {
  font-weight: bold;
  margin-right: 15px;
  margin-bottom: 0;
}

.infoText span {
  font-weight: normal;
}

.table {
  border-collapse: collapse;
  width: 100%;
}

.table th,
.table td {
  border: 1px solid black;
  text-align: center;
  padding: 10px 15px;
}

.images {
  border: 1px solid black;
  border-top: none;
  text-align: center;
  padding: 15px;
}

.screenshot {
  margin-bottom: 40px;

  &:last-of-type {
    margin-bottom: 0;
  }
}
</style>

<template>
  <component :is="component" />
</template>

<script>
import Screenshots from '@/components/pages/computers/log/screenshots/view-header.vue';
import Keyboard from '@/components/pages/computers/log/keyboard/view-header.vue';
import Programs from '@/components/pages/computers/log/programs/view-header.vue';
import VisitedSites from '@/components/pages/computers/log/visited-sites/view-header.vue';
import Clipboard from '@/components/pages/computers/log/clipboard/view-header.vue';
import InstalledPrograms from '@/components/pages/computers/log/installed-programs/view-header.vue';
import Files from '@/components/pages/computers/log/files/view-header.vue';
import Printer from '@/components/pages/computers/log/printer/view-header.vue';
import ExternalDrives from '@/components/pages/computers/log/external-drives/view-header.vue';
import SocialNetworks from '@/components/pages/computers/log/social-networks/view-header.vue';
import SearchQueries from '@/components/pages/computers/log/search-queries/view-header.vue';
import FileUpload from '@/components/pages/computers/log/file-upload/view-header.vue';
import WebMail from '@/components/pages/computers/log/web-mail/view-header.vue';
import Messengers from '@/components/pages/computers/log/messengers/view-header.vue';
import Computer from '@/components/pages/computers/log/computer/view-header.vue';
import EmailClients from '@/components/pages/computers/log/email-clients/view-header.vue';
import ShadowCopyings from '@/components/pages/computers/log/shadow-copyings/view-header.vue';
import NetworkAccesses from '@/components/pages/computers/log/network-accesses/view-header.vue';
import NetworkFiles from '@/components/pages/computers/log/network-files/view-header.vue';
import HardwareChanges from '@/components/pages/computers/log/hardware-changes/view-header.vue';
import WebcamsAndMicrophones from '@/components/pages/computers/log/webcams-and-microphones/view-header.vue';
import MicRecords from '@/components/pages/computers/log/mic-records/view-header.vue';

export default {
  computed: {
    component() {
      switch (this.$route.params.log) {
        case 'screenshots':
          return Screenshots;
        case 'keyboard':
          return Keyboard;
        case 'programs':
          return Programs;
        case 'visited-sites':
          return VisitedSites;
        case 'clipboard':
          return Clipboard;
        case 'installed-programs':
          return InstalledPrograms;
        case 'files':
          return Files;
        case 'printer':
          return Printer;
        case 'external-drives':
          return ExternalDrives;
        case 'social-networks':
          return SocialNetworks;
        case 'search-queries':
          return SearchQueries;
        case 'file-upload':
          return FileUpload;
        case 'web-mail':
          return WebMail;
        case 'messengers':
          return Messengers;
        case 'computer':
          return Computer;
        case 'email-clients':
          return EmailClients;
        case 'shadow-copyings':
          return ShadowCopyings;
        case 'network-accesses':
          return NetworkAccesses;
        case 'network-files':
          return NetworkFiles;
        case 'hardware-changes':
          return HardwareChanges;
        case 'webcams-and-microphones':
          return WebcamsAndMicrophones;
        case 'mic-records':
          return MicRecords;
        default:
          throw Error(`Unknown control log ${this.route.params.log}`);
      }
    },
  },
};
</script>

<template>
  <div :class="$style.root">
    <search :class="$style.sitesSearch" />
    <div :class="$style.inputContainer">
      <div :class="$style.inputLabel">
        Сайты, на которых проведено более
      </div>
      <input
        v-model="durationFilter"
        type="number"
        :class="$style.inputNumber"
      >
      <div :class="$style.inputLabel">
        сек
      </div>
    </div>
    <div :class="$style.optContainer">
      <select
        :class="$style.select"
        :value="$route.query.category"
        @change="changeCategory"
      >
        <option :value="undefined">
          Все категории
        </option>
        <option
          v-for="category in categories"
          :key="category"
          :value="category"
        >
          {{ category }}
        </option>
      </select>
      <export />
    </div>
  </div>
</template>

<script>
import Search from '@/components/pages/computers/log/screenshots/components-header/search.vue';
import Export from '@/components/pages/computers/log/screenshots/components-header/export.vue';

export default {
  components: {
    Search,
    Export,
  },
  data() {
    return {
      durationFilter: this.$route.query.durationFilter,
    };
  },
  computed: {
    categories() {
      return this.$store.state.logs.visitedSites.categories;
    },
  },
  watch: {
    '$route.query.durationFilter': function (val) {
      // eslint-disable-line
      this.durationFilter = val;
    },
    durationFilter(val) {
      if (val) {
        this.$router.replace({
          query: { ...this.$route.query, durationFilter: val },
        });
      } else {
        const query = { ...this.$route.query };
        delete query.durationFilter;
        this.$router.replace({ query });
      }
    },
  },
  methods: {
    changeCategory(e) {
      const category = e.target.value;
      const query = { ...this.$route.query };

      if (category) {
        query.category = category;
      } else {
        delete query.category;
      }

      this.$router.replace({ query });
    },
  },
};
</script>

<style lang="scss" module>
.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1300px) {
    flex-direction: column;
  }
}

.button {
  background: $light-gray;
  border: 1px solid $borderColor;
  border-radius: 2px;
  color: $light-black;
  font-size: 14px;
  text-decoration: none;
  display: inline-block;
  padding: 10px 20px;
  transition: 0.1s ease-out;

  &:not(:last-child) {
    margin-right: 12px;
  }

  &.active {
    background: $light-blue;
    color: $blue;
  }
}

.btnExport {
  background: $light-blue;
  border: none;
  border-radius: 2px;
  color: $blue;
  font-size: 14px;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  transition: 0.1s ease-out;
}

.mr {
  margin-right: 10px;
}

.inputNumber {
  max-width: 50px;
}

.inputLabel {
  padding: 5px;
}

.inputContainer {
  display: inline-flex;
  align-items: center;
  align-self: flex-start;
  font-size: 13px;
  margin: 10px;
  @media (max-width: 1300px) {
    margin: 5px 0;
  }
}

.select {
  height: 35px;
  width: 120px;
  @media (max-width: 1532px) {
    margin-left: 5px;
  }
}

.sitesSearch {
  width: 100%;
  margin: 5px 0;
  input {
    width: 100%;
    padding: 6px;
    min-width: 123px;
  }
}

.optContainer {
  display: flex;
  @media (max-width: 1532px) {
    flex-direction: column;
    gap: 5px;
  }
  @media (max-width: 1300px) {
    align-self: flex-start;
  }
}
</style>

<template>
  <div :class="[$style.dropdown, $style.buttonContainer]">
    <button :class="[$style.printButton, $style.exportButton]">
      Экспорт в
    </button>
    <div :class="$style.dropdownСontent">
      <div
        v-for="option in exportOptions"
        :key="option.name"
        :v-bind:value="option"
        :class="$style.item"
        @click="exportTable(option.name)"
      >
        <div :class="$style.dropdownItem">
          {{ option.name }}
        </div>

        <div
          v-if="option.children"
          :class="$style.dropdownNestedСontent"
        >
          <div
            v-for="child in option.children"
            :key="child.name"
            :class="$style.dropdownNestedItem"
            :v-bind:value="child"
            @click="exportTable(child.name)"
          >
            {{ child.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import html2pdf from 'html-to-pdf-js';
import { logTypes } from '@/assets/scripts/dictionaries';
import * as dayjs from 'dayjs';

export default {
  data: () => ({
    exportOptions: [{ name: 'pdf' },
      { name: 'xls' },
      { name: 'csv' }],
  }),
  watch: {
    $route(route) {
      // eslint-disable-line func-names
      if (route.query.view && route.query.view === 'grid' && route.params.log === 'screenshots') {
        this.exportOptions = [{
          name: 'pdf',
          children: [{ name: 'Книжная ориентация' }, { name: 'Альбомная ориентация' }],
        }];
      } else this.exportOptions = [{ name: 'pdf' }, { name: 'xls' }, { name: 'csv' }];
    },
  },
  created() {
    if (this.$route.query.view && this.$route.query.view === 'grid' && this.$route.params.log === 'screenshots') {
      this.exportOptions = [{
        name: 'pdf',
        children: [{ name: 'Книжная ориентация' }, { name: 'Альбомная ориентация' }],
      }];
    }
  },
  methods: {
    async pdfExport(isGrid, isAlbum) {
      this.$store.commit('loader/setIsLoaderNeeded', true);

      const targetLog = logTypes.find((log) => log.slug === this.$route.params.log);
      const logname = targetLog.name;

      const dateFrom = new Date(this.$route.query.dateFrom);
      const dateTo = new Date(this.$route.query.dateTo);
      const dateFromFormatted = dayjs(dateFrom).format('DD.MM.YY');
      const dateToFormatted = dayjs(dateTo).format('DD.MM.YY');

      const target = document.getElementById('printable');
      const copy = target.cloneNode(true);
      const prtHtml = copy.innerHTML;

      let stylesHtml = '';
      [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ].forEach((node) => {
        stylesHtml += node.outerHTML;
      });
      stylesHtml
        += '<style type="text/css">html{width:21cm;height:29.7cm;}'
        + 'body{display:block!important;}.view-grid_grid_8vvtA{grid-template-columns:auto!important;}</style>';

      const element = `<!DOCTYPE html>
                      <html>
                        <head>
                          ${stylesHtml}
                        </head>
                        <body>
                          <div style="margin:5px 5px 15px 5px;display:flex;justify-content:center">
                            Данные: ${logname} c: ${dateFromFormatted} по: ${dateToFormatted} </div>
                          ${prtHtml}
                        </body>
                      </html>`;

      const opt = {
        margin: 10,
        filename: `${this.$route.params.log} - ${this.$route.query.dateFrom} - ${this.$route.query.dateTo}.pdf`,
        pagebreak: { mode: ['avoid-all', 'css'] },
        image: { type: 'jpeg', quality: 1 },
        html2canvas: { scale: 4 },
      };
      if (isGrid) {
        let pagesRes = [];

        if (isAlbum) {
          opt.jsPDF = { orientation: 'landscape' };

          const pagesStart = element.split(/(?=<div tabindex="2")/);

          if (!pagesStart[1]) pagesRes = pagesStart;
          else {
            const pictures = pagesStart[1].split(/(?=<div tabindex)/);
            const pages = [];
            pages.push(pagesStart[0]);
            pagesRes = pages.concat(pictures);
          }
        } else {
          const pagesStart = element.split(/(?=<div tabindex="3")/);

          if (!pagesStart[1]) pagesRes = pagesStart;
          else {
            pagesRes.push(pagesStart[0]);
            const temp = document.createElement('div');
            temp.innerHTML = pagesStart[1];
            const pages = temp.children;

            for (let i = 0; i < pages.length; i += 2) {
              if (pages[i + 1]) pagesRes.push(pages[i].innerHTML + pages[i + 1].innerHTML);
              else pagesRes.push(pages[i].innerHTML);
            }
          }
        }

        let worker = html2pdf().set(opt).from(pagesRes[0]).toPdf();
        pagesRes.slice(1).forEach((page) => {
          worker = worker.get('pdf').then((pdf) => {
            pdf.addPage();
          }).from(page).toContainer()
            .toCanvas()
            .toPdf();
        });
        await worker.save();
      } else await html2pdf().set(opt).from(element).save();
      this.$store.commit('loader/setIsLoaderNeeded', false);
    },
    async exportTable(exportType) {
      if (exportType === 'pdf' && (this.$route.params.log !== 'screenshots'
        || (this.$route.params.log === 'screenshots'
          && (!this.$route.query.view || this.$route.query.view !== 'grid')))) {
        await this.pdfExport(false, false);
      } else if (exportType === 'Книжная ориентация') await this.pdfExport(true, false);
      else if (exportType === 'Альбомная ориентация') await this.pdfExport(true, true);
      else {
        const target = document.getElementById('printable');
        const copy = target.cloneNode(true);

        const table = copy.getElementsByTagName('table')[0];

        if (table) {
          const images = table.getElementsByTagName('img');

          for (let i = images.length; i-- > 0;) {
            images[i].parentNode.removeChild(images[i]);
          }
          let filename = `${this.$route.params.log} - ${this.$route.query.dateFrom}
   - ${this.$route.query.dateTo}`;
          if (exportType === 'xls') {
            const dataType = 'application/vnd.ms-excel';

            const tableHTML = table.outerHTML.replace(/ /g, '%20');

            filename += '.xls';

            const downloadLink = document.createElement('a');
            document.body.appendChild(downloadLink);

            if (navigator.msSaveOrOpenBlob) {
              const blob = new Blob(['\ufeff', tableHTML], {
                type: dataType,
              });
              navigator.msSaveOrOpenBlob(blob, filename);
            } else {
              downloadLink.href = `data:${dataType}, ${tableHTML}`;
              downloadLink.download = filename;
              downloadLink.click();
            }
          } else {
            let content = null;
            let type = '';
            if (exportType === 'csv') {
              filename += '.csv';

              const csv = [];
              const rows = table.querySelectorAll('table tr');

              for (let i = 0; i < rows.length; i++) {
                const row = []; const
                  cols = rows[i].querySelectorAll('td, th');

                for (let j = 0; j < cols.length; j++) {
                  row.push(cols[j].innerText.replaceAll(',', ' '));
                }

                csv.push(row.join(','));
              }

              content = csv.join('\n');
              type = 'text/csv';
            } /* else {
              filename += '.rtf';
              const html = table.outerHTML;
              const rtfContent = htmlToRtf.convertHtmlToRtf(html);
              const self = this;

              // eslint-disable-next-line
              content = rtfContent.replace(/[^\x00-\x7F]/g, (c) => `\\u${self.rftcharcode(c)}?`);
              type = 'application/rtf'
            } */
            const file = new Blob([content], { type });
            const downloadLink = document.createElement('a');
            downloadLink.download = filename;
            downloadLink.href = window.URL.createObjectURL(file);
            downloadLink.style.display = 'none';
            document.body.appendChild(downloadLink);
            downloadLink.click();
          }
        }
      }
    },
    rftcharcode(c) {
      const cc = c.charCodeAt();
      return cc <= 32767 ? cc : cc - 65535;
    },
  },
};
</script>

<style lang="scss" module>
.printButton {
  cursor: pointer;
  display: block;

  width: 120px;
  height: 35px;
  float: right;

  background: $blue;
  border: 2px solid $blue;
  border-radius: 2px;

  font: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: $white;

  margin: 0px 5px;
}

.exportButton {
  background: white;
  color: #2985bf;
}

.dropdown {
  position: relative;
}

.dropdownСontent {
  display: none;
  position: absolute;
  background-color: white;
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.2);
  z-index: 50;
  top: 35px;
  width: 120px;
  font-size: 14px;
  left: 5px;
}

.dropdownNestedСontent {
  display: none;
  position: absolute;
  background-color: white;
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.2);
  z-index: 50;
  top: 35px;
  width: 120px;
  font-size: 14px;
  left: 5px;
}

.dropdownItem {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.dropdownNestedItem {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.item:hover {
  background-color: #eee;

  .dropdownNestedСontent {
    display: block;
  }
}

.dropdown:hover .dropdownСontent {
  display: block;
}

.dropdownNestedItem:hover {
  background-color: #eee;
}
</style>

import { getScreenshotLink } from '@/api/methods/computers';

export function getScreenshotUrl(filepath) {
  return getScreenshotLink(
    this.$route.params.computer,
    filepath,
  );
}

export function validateEmail(email) {
  // eslint-disable-next-line
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) || email === '') return true;
  return false;
}

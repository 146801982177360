<template>
  <div :class="$style.root">
    <search />
    <export />
    <!-- <button
      type="button"
      :class="$style.btnExport"
      disabled
    >
      <inline-svg
        :src="require('@/assets/images/icons/computer/screenshots/modal/download.svg')"
        alt="Загрузить"
        title="Загрузить"
        :class="$style.mr"
      />Экспорт
    </button> -->
  </div>
</template>

<script>
// import { InlineSvgComponent } from 'vue-inline-svg';
import Search from '@/components/pages/computers/log/screenshots/components-header/search.vue';
import Export from '@/components/pages/computers/log/screenshots/components-header/export.vue';

export default {
  components: {
    // inlineSvg: InlineSvgComponent,
    Search,
    Export,
  },
};
</script>

<style lang="scss" module>
.root {
  display: flex;
  justify-content: space-between;
}

.button {
  background: $light-gray;
  border: 1px solid $borderColor;
  border-radius: 2px;
  color: $light-black;
  font-size: 14px;
  text-decoration: none;
  display: inline-block;
  padding: 10px 20px;
  transition: 0.1s ease-out;

  &:not(:last-child) {
    margin-right: 12px;
  }

  &.active {
    background: $light-blue;
    color: $blue;
  }
}

.btnExport {
  background: $light-blue;
  border: none;
  border-radius: 2px;
  color: $blue;
  font-size: 14px;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  transition: 0.1s ease-out;
}

.mr {
  margin-right: 10px;
}
</style>

import dayjs from 'dayjs';

export const date = (val) => dayjs(val).format('DD.MM.YYYY');

export const dateTime = (val) => dayjs(val).format('DD.MM.YYYY HH:mm:ss');

/**
 * Форматирование длительности
 * @param {number} val количество секунд
 * @returns string
 */
export const duration = (val) => {
  if (val <= 0) {
    return val.toString(10);
  }

  const result = [];

  let restValue = val;

  const hours = restValue >= 3600 ? Math.floor(restValue / 3600) : 0;
  if (hours) {
    restValue -= hours * 3600;
    result.push(`${hours}ч`);
  }

  const minutes = restValue >= 60 ? Math.floor(restValue / 60) : 0;
  if (minutes) {
    restValue -= minutes * 60;
    result.push(`${minutes}мин`);
  }

  if (restValue) {
    result.push(`${restValue}с`);
  }

  return result.join(' ');
};

<template>
  <div :class="$style.root">
    <search :class="$style.mailSearch" />
    <div :class="$style.inline">
      <select
        :class="$style.select"
        :value="$route.query.mailType"
        @change="changeMailType"
      >
        <option :value="undefined">
          Все письма
        </option>
        <option v-for="mailType in mailTypes" :key="mailType" :value="mailType">
          {{ mailType }}
        </option>
      </select>

      <div :class="$style.domainContainer">
        <label>
          <span :class="$style.visuallyHidden">Домен компании</span>
          <input
            v-model="domain"
            :class="$style.input"
            type="text"
            placeholder="Домен компании"
          >
        </label>
      </div>
    </div>
    <div :class="$style.customCheckBoxContainer" @click="toggleCheckBox()">
      <input
        id="attachmentOnlyCheckbox"
        v-model="attachmentOnly"
        type="checkbox"
        :class="$style.customCheckbox"
      >
      <label :class="$style.checkboxLabel">
        <check-mark v-if="attachmentOnly" />
      </label>
      <label :class="$style.textLabel">
        Только <br>
        с вложением
      </label>
    </div>
    <export />
  </div>
</template>

<script>
import Search from '@/components/pages/computers/log/screenshots/components-header/search.vue';
import CheckMark from '@/components/common/filter/check-mark.vue';
import Export from '@/components/pages/computers/log/screenshots/components-header/export.vue';

export default {
  components: {
    Search,
    CheckMark,
    Export,
  },
  data() {
    return {
      attachmentOnly: this.$route.query.attachmentOnly,
      mailTypes: ['Только внешние', 'Только внутренние'],
      domain: this.$route.query.domain,
    };
  },

  watch: {
    '$route.query.attachmentOnly': function (val) {
      // eslint-disable-line
      this.attachmentOnly = val;
    },
    '$route.query.domain': function (val) {
      // eslint-disable-line
      this.domain = val;
    },
    attachmentOnly(val) {
      if (val) {
        this.$router.replace({
          query: { ...this.$route.query, attachmentOnly: val },
        });
      } else {
        const query = { ...this.$route.query };
        delete query.attachmentOnly;
        this.$router.replace({ query });
      }
    },
    domain(val) {
      if (val) {
        this.$router.replace({ query: { ...this.$route.query, domain: val } });
      } else {
        const query = { ...this.$route.query };
        delete query.domain;
        this.$router.replace({ query });
      }
    },
  },
  methods: {
    toggleCheckBox() {
      this.attachmentOnly = !this.attachmentOnly;
    },
    changeMailType(e) {
      const mailType = e.target.value;
      const query = { ...this.$route.query };

      if (mailType) {
        query.mailType = mailType;
      } else {
        delete query.mailType;
      }

      this.$router.replace({ query });
    },
  },
};
</script>

<style lang="scss" module>
.root {
  display: flex;
  justify-content: space-between;
  gap: 3%;
  @media (max-width: 1830px) {
    flex-direction: column;
  }
}

.button {
  background: $light-gray;
  border: 1px solid $borderColor;
  border-radius: 2px;
  color: $light-black;
  font-size: 14px;
  text-decoration: none;
  display: inline-block;
  padding: 10px 20px;
  transition: 0.1s ease-out;

  &:not(:last-child) {
    margin-right: 12px;
  }

  &.active {
    background: $light-blue;
    color: $blue;
  }
}

.btnExport {
  background: $light-blue;
  border: none;
  border-radius: 2px;
  color: $blue;
  font-size: 14px;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  transition: 0.1s ease-out;
}

.mr {
  margin-right: 10px;
}

.customCheckBoxContainer {
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  margin: 5px 0;
  & * {
    cursor: pointer;
  }
  .textLabel {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    padding-left: 5px;
    font-style: normal;
    font-size: 14px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .customCheckbox {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  input:checked + .checkboxLabel > svg {
    animation: draw-checkbox ease-in-out 0.2s forwards;
  }
  .checkboxLabel:active::after {
    background-color: #fbfbfb;
  }
  .checkboxLabel {
    color: black;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 14px;
    cursor: pointer;
    position: relative;
    display: block;
    &:after {
      content: '';
      height: 14px;
      width: 14px;
      float: left;
      border: 1px solid #9f9f9f;
      border-radius: 4px;
      transition: 0.15s all ease-out;
      background-color: white;
    }
  }
  input:checked + .checkboxLabel:after {
    background-color: $blue;
    border: 1px solid $blue;
  }
  svg {
    position: absolute;
    left: 4px;
    top: 4px;
    stroke-dasharray: 33;
  }
  @keyframes draw-checkbox {
    0% {
      stroke-dashoffset: 33;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
}
.select {
  height: 35px;
  margin-right: 5px;
  width: 100%;
  min-width: 142px;
}

.visuallyHidden {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}

.input {
  border: 1px solid $borderColor;
  color: $black;
  font-size: 14px;
  line-height: 20px;
  padding: 6px;
  min-width: 123px;
  margin-right: 5px;
  width: 100%;

  &::placeholder {
    color: dakren($dark-gray, 3%);
  }
}

.mailSearch {
  width: 100%;
  margin: 5px 0;
  input {
    width: 100%;
    padding: 6px;
    min-width: 123px;
  }
}

.inline {
  display: inline-flex;
  width: 100%;
  margin: 5px 0;
}

.domainContainer {
  width: 100%;
}
</style>
